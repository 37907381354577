<template>
  <v-dialog
    v-model="dialog"
    max-width="500px"
    transition="dialog-transition"
  >
    <template v-slot:activator="{ on }">
      <v-btn
        small text icon :color="color"
        v-on="on"
      >
        <v-icon>fas fa-question-circle</v-icon>
      </v-btn>
    </template>
    <v-card class="pa-3">
      <v-btn
        fab small
        color="error white--text"
        top right
        absolute style="z-index: 99"
        @click.native.stop="dialog = false"
      >
        <v-icon>fas fa-times</v-icon>
      </v-btn>
      <youtube
        v-if="dialog"
        :videoId="videoId"
        player-width="100%"
        :player-vars="{autoplay: 0}"
      ></youtube>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: ['videoId', 'color'],
  data () {
    return {
      dialog: false
    }
  }
}
</script>
<style scoped>
  >>> .v-dialog {
    overflow-y: visible;
  }
</style>
